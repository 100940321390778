<!--
 * @Author: ch3nh2
 * @Date: 2022-12-08 16:10:54
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-09 01:06:06
 * @FilePath: \beilunchanye_fe_20211208\src\components\companyDetails\Industry.vue
 * @Description: none
-->
<template>
  <div class="company_details_data_item_data_comprehensive">
    <div class="company_details_data_item_data_comprehensive_option">
      <div class="company_details_data_item_data_comprehensive_option_item">
        <span>行业类型：</span>
        <el-select v-model="typeValue" placeholder="请选择" size="small" :popper-append-to-body="false">
          <el-option v-for="item in data.company_industry" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="company_details_data_item_data_comprehensive_option_item">
        <div class="company_details_data_item_data_comprehensive_option_item_left">
          <el-radio-group v-model="radioValue" size="mini" fill="#2660D0">
            <el-radio-button label="comprehensive">综合实力</el-radio-button>
            <el-radio-button label="independent">自主研发</el-radio-button>
          </el-radio-group>
        </div>
        <div class="company_details_data_item_data_comprehensive_option_item_right">
          <div class="company_details_data_item_data_comprehensive_option_item_right_item">
            {{ (grade(data && data.grade) && grade(data && data.grade)[radioValue].grade) }}
          </div>
          <div class="company_details_data_item_data_comprehensive_option_item_right_item">
            (超出同行
            {{
                grade(data && data.grade)
                &&
                (grade(data && data.grade)[radioValue].proportion * 100).toFixed(2)
            }}
            %)
          </div>
        </div>
      </div>
    </div>
    <div class="company_details_data_item_data_comprehensive_chart">
      <RadarChart :data="strength(data.tech_rd_strength)" />
    </div>
  </div>
</template>

<script>
import RadarChart from './RadarChart.vue';
export default {
  name: "Industry",
  props: {
    data: {
      default: () => null
    },
  },
  data() {
    return {
      radioValue: 'comprehensive',
      typeValue: ''
    };
  },
  computed: {
    grade() {
      const gradeName = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E'
      }
      return function (val) {
        return val.find((value) => {
          value.independent = {
            grade: gradeName[value.independent_rd_grade],
            proportion: value.independent_rd_exceed_proportion
          }
          value.comprehensive = {
            grade: gradeName[value.comprehensive_strength_grade],
            proportion: value.comprehensive_strength_exceed_proportion
          }
          return this.typeValue == value.industry_id
        })
      }
    },
    strength() {
      return function (val) {
        return val.find((value) => {
          return this.typeValue == value.industry_id
        })
      }
    }
  },
  components: {
    RadarChart
  },
  methods: {
    defaultSelect() {
      const opt = this.data && this.data.company_industry
      if (opt && opt.length > 0) {
        this.typeValue = opt[0].value
      }
    },
  },
  mounted() {
    this.defaultSelect()
  }
};
</script>
