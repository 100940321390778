<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-14 13:51:55
 * @FilePath: /beilunchanye_fe_20211208/src/components/companyDetails/PieChart.vue
 * @Description: 
-->
<template>
  <div class="pie_chart_wrap">
    <div id="PieChart" :style="{ width: '225px', height: '165px' }"></div>
    <div class="pie_chart_circle">
      <div class="pie_chart_circle">技术评级</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  data() {
    return {}
  },
  props: {
    data: {
      default: []
    }
  },
  computed: {

  },
  methods: {
    drawPieChart() {
      const pieChart = this.$echarts.init(document.getElementById('PieChart'))
      const infoData = [
        { value: this.data[5].patent_num, name: '5星专利' },
        { value: this.data[4].patent_num, name: '4星专利' },
        { value: this.data[3].patent_num, name: '3星专利' },
        { value: this.data[2].patent_num, name: '2星专利' },
        { value: this.data[1].patent_num, name: '1星专利' }
      ]
      const option = {
        tooltip: {
          trigger: 'item',
          confine: true
        },
        legend: {
          x: 150,
          y: 22,
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            color: '#fff',
            fontSize: '14',
          },
        },
        color: ['#459AF8', '#34DCC0', '#4FF9FF', '#F6AD49', '#E9565D'],
        series: [{
          type: 'pie',
          radius: ['80%', '65%'],
          label: {
            show: false
          },
          center: [70, '50%'],
          data: infoData,
        }]
      }
      pieChart.setOption(option)
    }
  },
  mounted() {
    this.drawPieChart()
  },
  beforeDestory() {

  }
}
</script>

<style lang="less">
.pie_chart_wrap {
  position: relative;

  .pie_chart_circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 24px;
    width: 90px;
    height: 90px;
    border: 1.5px solid #459AF8;
    transform: translateY(-49.5%);
    border-radius: 50%;

    .pie_chart_circle {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 78px;
      height: 78px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>