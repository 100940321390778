<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-09 02:08:55
 * @FilePath: \beilunchanye_fe_20211208\src\components\companyDetails\index.vue
 * @Description: 
-->
<template>
  <div class="company_details">
    <transition name="el-zoom-in-center">
      <div v-show="visible" :class="`company_details_modal ${name}`">
        <div v-if="visible" class="company_details_close" @click="onClose"></div>
        <div v-if="visible" class="company_details_content">
          <div class="company_details_title_wrap">
            <div class="company_details_title">{{ (data && data.company_name) }}</div>
            <div v-if="(data && data.company_info)" class="company_details_subtitle">{{ (data && data.company_info) }}
            </div>
          </div>
          <div class="company_details_data_wrap">
            <!-- 行业相关度TOP5评价 -->
            <div class="company_details_data_item">
              <div class="company_details_data_item_title_wrap">
                <div class="company_details_data_item_title">
                  <div class="company_details_data_item_title_icon"></div>
                  <div class="company_details_data_item_title_text">行业相关度TOP5评价</div>
                </div>
                <div class="company_details_data_item_title_line">
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                </div>
              </div>
              <div
                v-if="(data && data.company_industry) && (data && data.comprehensive) && (data && data.tech_rd_strength)"
                class="company_details_data_item_content">
                <Industry :data="data" />
              </div>
              <div v-else class="company_details_data_item_nodata">
                <div class="company_details_data_item_nodata_icon"></div>
              </div>
            </div>
            <!-- 主要技术 -->
            <div class="company_details_data_item">
              <div class="company_details_data_item_title_wrap">
                <div class="company_details_data_item_title">
                  <div class="company_details_data_item_title_icon"></div>
                  <div class="company_details_data_item_title_text">主要技术</div>
                </div>
                <div class="company_details_data_item_title_line">
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                </div>
              </div>
              <div v-if="(data && data.company_keywords.length > 0)" class="company_details_data_item_content">
                <WordCloud :wordCloudData="(data && data.company_keywords)" />
              </div>
              <div v-else class="company_details_data_item_nodata">
                <div class="company_details_data_item_nodata_icon"></div>
              </div>
            </div>
            <!-- 技术质量 -->
            <div class="company_details_data_item">
              <div class="company_details_data_item_title_wrap">
                <div class="company_details_data_item_title">
                  <div class="company_details_data_item_title_icon"></div>
                  <div class="company_details_data_item_title_text">技术质量</div>
                </div>
                <div class="company_details_data_item_title_line">
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                </div>
              </div>
              <div
                v-if="((data && data.intellectual_property_statistics) && (data && data.patent_star && data.patent_star.length > 0))"
                class="company_details_data_item_content">
                <div class="company_details_data_item_property_text">
                  <div class="company_details_data_item_property_text_item">
                    <div>
                      发明专利：
                      <CountUp :delay="1000"
                        :endVal="data.intellectual_property_statistics.independent_rd_invention_patent_num"
                        :options="{ decimalPlaces: 2 }" /> 件，占比
                      <CountUp :delay="1000" :endVal="data.intellectual_property_statistics.independent_rd_invention_patent_num ? (data.intellectual_property_statistics.independent_rd_invention_patent_num
                      / data.intellectual_property_statistics.invention_patent_num *
                      100) : 0" :options="{ decimalPlaces: 2 }" />%
                    </div>
                    <div>
                      软著：
                      <CountUp :delay="1000" :endVal="data.intellectual_property_statistics.software_copyright_num"
                        :options="{ decimalPlaces: 2 }" /> 项
                    </div>
                  </div>
                  <div class="company_details_data_item_property_text_item">
                    实用新型：
                    <CountUp :delay="1000"
                      :endVal="data.intellectual_property_statistics.independent_rd_utility_model_patent_num"
                      :options="{ decimalPlaces: 2 }" /> 件，占比
                    <CountUp :delay="1000" :endVal="data.intellectual_property_statistics.independent_rd_utility_model_patent_num
                  ?
                  (data.intellectual_property_statistics.independent_rd_utility_model_patent_num
                    / data.intellectual_property_statistics.utility_model_patent_num *
                    100) : 0" :options="{ decimalPlaces: 2 }" />%
                  </div>
                </div>
                <div class="company_details_data_item_property_chart">
                  <PieChart :data="(data && data.patent_star)" />
                  <div class="company_details_data_item_property_statistics">
                    <div class="company_details_data_item_property_statistics_item">
                      <CountUp :delay="1000" :endVal="data.patent_star[5].patent_num" />件，占比
                      <CountUp :delay="1000" :endVal="(data.patent_star[5].patent_num_proportion * 100)"
                        :options="{ decimalPlaces: 2 }" />%
                    </div>
                    <div class="company_details_data_item_property_statistics_item">
                      <CountUp :delay="1000" :endVal="data.patent_star[4].patent_num" />件，占比
                      <CountUp :delay="1000" :endVal="(data.patent_star[4].patent_num_proportion * 100)"
                        :options="{ decimalPlaces: 2 }" />%
                    </div>
                    <div class="company_details_data_item_property_statistics_item">
                      <CountUp :delay="1000" :endVal="data.patent_star[3].patent_num" />件，占比
                      <CountUp :delay="1000" :endVal="(data.patent_star[3].patent_num_proportion * 100)"
                        :options="{ decimalPlaces: 2 }" />%
                    </div>
                    <div class="company_details_data_item_property_statistics_item">
                      <CountUp :delay="1000" :endVal="data.patent_star[2].patent_num" />件，占比
                      <CountUp :delay="1000" :endVal="(data.patent_star[2].patent_num_proportion * 100)"
                        :options="{ decimalPlaces: 2 }" />%
                    </div>
                    <div class="company_details_data_item_property_statistics_item">
                      <CountUp :delay="1000" :endVal="data.patent_star[1].patent_num" />件，占比
                      <CountUp :delay="1000" :endVal="(data.patent_star[1].patent_num_proportion * 100)"
                        :options="{ decimalPlaces: 2 }" />%
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="company_details_data_item_nodata">
                <div class="company_details_data_item_nodata_icon"></div>
              </div>
            </div>
            <!-- 研发效率 -->
            <div class="company_details_data_item"
              :style="{ marginTop: ((data && data.distribution.length > 0) && data && data.rd_efficiency) ? '-60px' : '' }">
              <div class="company_details_data_item_title_wrap">
                <div class="company_details_data_item_title">
                  <div class="company_details_data_item_title_icon"></div>
                  <div class="company_details_data_item_title_text">研发效率</div>
                </div>
                <div class="company_details_data_item_title_line">
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                  <div class="company_details_data_item_title_line_child"></div>
                </div>
              </div>
              <div v-if="((data && data.distribution.length > 0) && data && data.rd_efficiency)"
                class="company_details_data_item_content">
                <div class="company_details_data_item_distribution_data">
                  <div class="company_details_data_item_distribution_data_item">
                    该企业近三年的研发效率平均值
                    <b>(</b>
                    <CountUp :delay="1000" :endVal="data.rd_efficiency.recent_years_company_rd_efficiency_avg"
                      :options="{ decimalPlaces: 2 }" />
                    <b>)</b>
                  </div>
                  <div class="company_details_data_item_distribution_data_item">
                    {{ data.rd_efficiency &&
                        (data.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                        > (data.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ?
                        '高于' : (data.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                          ==
                          (data.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '等于' :
                          (data.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                            < (data.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '低于' : ''
                    }}同行业研发效率平均值 <b>
                      (</b>
                      <CountUp :delay="1000" :endVal="data.rd_efficiency.recent_years_cluster_rd_efficiency_avg"
                        :options="{ decimalPlaces: 2 }" />
                      <b>)</b>
                  </div>
                </div>
                <div class="company_details_data_item_distribution_chart">
                  <LineChart :data="data && data.distribution" />
                </div>
              </div>
              <div v-else class="company_details_data_item_nodata">
                <div class="company_details_data_item_nodata_icon"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CountUp from "vue-countup-v2";
import Industry from './Industry.vue';
import PieChart from './PieChart.vue';
import LineChart from './LineChart.vue';
import WordCloud from './WordCloud.vue';
export default {
  name: 'CompanyDetails',
  data() {
    return {

    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'dahang'
    },
    data: {
      default: null
    },
    onClose: {
      type: Function,
      default: () => { }
    }
  },
  watch: {},
  components: {
    CountUp,
    Industry,
    PieChart,
    LineChart,
    WordCloud
  },
  methods: {

  },
  mounted() {

  },
  beforeDestory() {

  }
}
</script>


<style lang="less">
.company_details {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .company_details_modal {
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;

    .company_details_close {
      position: absolute;
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: inline-block;
      background-size: 100%;
      background-repeat: no-repeat;

      &:active {
        opacity: 0.5;
      }
    }

    .company_details_content {
      margin: 0 35px;
      padding: 35px 0;

      .company_details_title_wrap {
        color: #FFFFFF;
        margin-bottom: 10px;

        .company_details_title {
          font-size: 20px;
          margin-bottom: 18px;
          font-weight: bold;
          text-align: center;
        }

        .company_details_subtitle {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
          font-weight: 400;
          text-indent: 2em;
          line-height: 20px;
        }
      }

      .company_details_data_wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .company_details_data_item {
          width: 385px;

          .company_details_data_item_title_wrap {
            margin: 20px 0;

            .company_details_data_item_title {
              display: flex;
              align-items: center;
              margin-bottom: 2px;

              .company_details_data_item_title_icon {
                margin-right: 5px;
                background-size: 100%;
                background-repeat: no-repeat;
              }

              .company_details_data_item_title_text {
                font-size: 18px;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .company_details_data_item_title_line {
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }

          .company_details_data_item_content {
            .company_details_data_item_data_comprehensive {
              .company_details_data_item_data_comprehensive_option {
                .company_details_data_item_data_comprehensive_option_item {
                  display: flex;
                  align-items: center;
                  margin-bottom: 18px;

                  &:nth-child(2) {
                    justify-content: space-between
                  }

                  .company_details_data_item_data_comprehensive_option_item_left {
                    .el-radio-button__inner {
                      background: transparent;
                      color: #FFFFFF;
                    }
                  }

                  .company_details_data_item_data_comprehensive_option_item_right {
                    text-align: center;

                    .company_details_data_item_data_comprehensive_option_item_right_item {
                      &:nth-child(1) {
                        font-size: 28px;
                        font-weight: bold;
                        color: #ED474B;
                      }

                      &:nth-child(2) {
                        padding: 4px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        background: #ED474B;
                      }
                    }
                  }
                }
              }

              .company_details_data_item_data_comprehensive_chart {}
            }

            .company_details_data_item_property_text {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              margin-bottom: 20px;

              span {
                font-size: 24px;
                font-weight: bold;
                font-family: 'bigdsfont';
              }

              .company_details_data_item_property_text_item {
                &:nth-child(1) {
                  margin-bottom: 16px;
                  display: flex;
                  justify-content: space-between;
                }
              }

            }

            .company_details_data_item_property_chart {
              position: relative;

              .company_details_data_item_property_statistics {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                .company_details_data_item_property_statistics_item {
                  min-width: 150px;
                  margin-bottom: 3.5px;

                  &:nth-child(1) {
                    span {
                      color: #459AF8;
                    }
                  }

                  &:nth-child(2) {
                    span {
                      color: #34DCC0;
                    }
                  }

                  &:nth-child(3) {
                    span {
                      color: #4FF9FF;
                    }
                  }

                  &:nth-child(4) {
                    span {
                      color: #F6AD49;
                    }
                  }

                  &:nth-child(5) {
                    span {
                      color: #E9565D;
                    }
                  }

                  span {
                    font-size: 20px;
                    font-weight: bold;
                    font-family: 'bigdsfont';

                    &:nth-child(1) {
                      margin-right: 3.5px;
                    }
                  }
                }
              }
            }

            .company_details_data_item_distribution_data {

              .company_details_data_item_distribution_data_item {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 10px;

                &:nth-child(1) {

                  b,
                  span {
                    color: #459AF8;
                  }
                }

                &:nth-child(2) {

                  b,
                  span {
                    color: #49F9E2;
                  }
                }

                span {
                  font-size: 20px;
                  font-weight: bold;
                  font-family: 'bigdsfont';
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .company_details_data_item_distribution_chart {}
          }

          .company_details_data_item_nodata {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 268px;

            .company_details_data_item_nodata_icon {
              width: 160px;
              height: 110px;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        .company_details_data_item_02_max {}
      }
    }
  }

  .dahang {
    width: 880px;
    height: 860px;
    background-image: url(../../assets/images/companyDetails/dahang_bg.png);

    .company_details_close {
      top: 21px;
      right: 35px;
      background-image: url(../../assets/images/companyDetails/dahang_close.png);
    }

    .company_details_data_item_title_icon {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/images/dahang/title-icon.png);
    }

    .company_details_data_item_title_line {
      display: flex;
      align-items: center;

      .company_details_data_item_title_line_child {

        &:nth-child(1) {
          width: 10px;
          height: 2px;
          margin-right: 6px;
          background: #9CECFF;
        }

        &:nth-child(2) {
          width: 10px;
          height: 2px;
          margin-right: 6px;
          background: #387E94;
        }

        &:nth-child(3) {
          width: 329px;
          height: 2px;
          background: linear-gradient(90deg, #387E94 0%, transparent 100%);
        }
      }
    }

    .company_details_data_item_property_text span {
      color: #459AF8;
    }

    .company_details_data_item_nodata_icon {
      background-image: url(../../assets/images/companyDetails/dahang_nodata.png);
    }
  }

  .haixing {
    width: 860px;
    height: 868px;
    background-image: url(../../assets/images/companyDetails/haixing_bg.png);

    .company_details_close {
      top: 30px;
      right: 23px;
      background-image: url(../../assets/images/companyDetails/haixing_close.png);
    }

    .company_details_close {
      top: 21px;
      right: 35px;
      background-image: url(../../assets/images/companyDetails/dahang_close.png);
    }

    .company_details_data_item_title_icon {
      width: 26px;
      height: 26px;
      background-image: url(../../assets/images/haixing/title-icon.png);
    }

    .company_details_data_item_title_line {
      width: 346px;
      height: 4px;
      background-image: url(../../assets/images/haixing/details_title_line.png);
    }

    .company_details_data_item_property_text span {
      color: #459AF8;
    }

    .company_details_data_item_nodata_icon {
      background-image: url(../../assets/images/companyDetails/haixing_nodata.png);
    }
  }

  .other {
    width: 862px;
    height: 864px;
    background-image: url(../../assets/images/companyDetails/other_bg.png);

    .company_details_close {
      top: 19px;
      right: 23px;
      background-image: url(../../assets/images/companyDetails/other_close.png);
    }

    .company_details_data_item_title_icon {
      width: 26px;
      height: 26px;
      background-image: url(../../assets/images/other/item-title-icon-dl.png);
    }

    .company_details_data_item_title_line {
      width: 346px;
      height: 2px;
      background: linear-gradient(90deg, #2890CD 0%, transparent 100%);
    }

    .company_details_data_item_property_text span {
      color: #B8DBFF;
    }

    .company_details_data_item_nodata_icon {
      background-image: url(../../assets/images/companyDetails/other_nodata.png);
    }
  }
}

.el-select-dropdown__empty {
  padding: 15px 20px;
}

.el-select .el-input__inner:focus,
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #81ade9;
}

.el-select .el-input .el-select__caret {
  color: #ffffff;
  font-weight: bold;
}

.el-select-dropdown {
  background-color: #040a22;
  border: 1px solid #81ade9;
}

.popper__arrow::after {
  border-bottom-color: #81ade9 !important
}

.el-select-dropdown__item.selected {
  color: #ffffff;
}

.el-select-dropdown__item {
  color: #81ade9;
  font-weight: bold;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #2660d0
}

.el-scrollbar__bar.is-vertical {
  display: none;
}

.el-scrollbar__wrap {
  &::-webkit-scrollbar {
    display: none;
  }
}

.el-input__inner {
  background-color: transparent !important;
  color: #ffffff !important;
}
</style>