<!--
 * @Author: ch3nh2
 * @Date: 2022-12-08 23:24:10
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-09 00:46:57
 * @FilePath: \beilunchanye_fe_20211208\src\components\companyDetails\RadarChart.vue
 * @Description: none
-->
<template>
  <div id="RadarChart" :style="{ width: '360px', height: '180px' }">
  </div>
</template>
<script>
export default {
  data() {
    return {
      chartOpt: {
        legend: {
          data: ['企业自主研发', '行业平均自主研发'],
          top: '60%',
          left: '65%',
          show: true,
          orient: 'vertical',
          itemHeight: 7,
          itemWidth: 7,
          textStyle: {
            color: '#fff',
          }
        },
        radar: {
          indicator: [
            { name: '技术布局', max: 100 },
            { name: '技术评级', max: 100 },
            { name: '技术影响', max: 100 },
            { name: '技术迭代率', max: 100 },
            { name: '技术研发效率', max: 100 },
          ],
          center: ['40%', '60%'],
        },
        color: ['#459AF8', '#49F9E2'],
        series: [
          {
            symbolSize: 0,
            type: 'radar',
            data: [
              {
                value: [],
                name: '行业平均自主研发',
                areaStyle: {
                  opacity: 0.5,
                  color: "#459AF8",
                  center: ['10%', '80%'],
                }
              },
              {
                value: [],
                name: '企业自主研发',
                areaStyle: {
                  opacity: 0.5,
                  color: "#49F9E2"
                }
              },
            ]
          }
        ]
      }
    }
  },
  props: {
    data: {
      default: () => null
    },
  },
  watch: {
    data: {
      handler(value) {
        if (value && this.radarChart) {
          value.industry = value.industry_tech_strength.map((item) => (item.value * 100).toFixed(2))
          value.company = value.company_tech_strength.map((item) => (item.value * 100).toFixed(2))
          const chartOpt = {
            series: [
              {
                symbolSize: 0,
                type: 'radar',
                data: [
                  {
                    value: value.industry,
                    name: '行业平均自主研发',
                    areaStyle: {
                      opacity: 0.5,
                      color: "#2176e4",
                      center: ['10%', '80%'],
                    }
                  },
                  {
                    value: value.company,
                    name: '企业自主研发',
                    areaStyle: {
                      opacity: 0.5,
                      color: "#4bc9a4"
                    }
                  },
                ]
              }
            ]
          }
          this.radarChart.setOption({ ...this.chartOpt, ...chartOpt })
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    drawRadarChart() {
      this.radarChart = this.$echarts.init(document.getElementById('RadarChart'))
      this.radarChart.setOption(this.chartOpt)
    }
  },
  mounted() {
    this.drawRadarChart()
  }
}
</script>