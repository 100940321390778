<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-08 23:07:00
 * @FilePath: \beilunchanye_fe_20211208\src\components\companyDetails\lineChart.vue
 * @Description: 
-->
<template>
  <div id="LineChart" :style="{ width: '400px', height: '260px' }"></div>
</template>

<script>
export default {
  name: 'LineChart',
  data() {
    return {}
  },
  props: {
    data: {
      default: []
    }
  },
  computed: {

  },
  methods: {
    drawLineChart() {
      const LineChart = this.$echarts.init(document.getElementById('LineChart'))
      const datetime = [], company = [], industry = [];
      this.data.map((item) => {
        datetime.push(String(item.date_time))
        company.push(item.company_rd_efficiency)
        industry.push(item.cluster_rd_efficiency)
      })
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          }
        },
        color: ['#459AF8', '#49F9E2'],
        legend: {
          itemHeight: 7,
          itemWidth: 7,
          x: 'center',
          y: 20,
          textStyle: {
            fontSize: 14,
            color: '#ffffff'
          },
          data: [
            { name: '企业自主研发', icon: 'rect' },
            { name: '行业平均自主研发', icon: 'rect' }]
        },
        grid: {
          bottom: 30,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            data: datetime,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            boundaryGap: false,
            nameTextStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgb(67,71,87)'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        series: [
          {
            name: '企业自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: company
          },
          {
            name: '行业平均自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: industry
          },
        ]
      };
      LineChart.setOption(option)
    }
  },
  mounted() {
    this.drawLineChart()
  },
  beforeDestory() {

  }
}
</script>