<!--
 * @Author: ch3nh2
 * @Date: 2022-12-08 16:10:54
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-12-08 23:14:23
 * @FilePath: \beilunchanye_fe_20211208\src\components\companyDetails\WordCloud.vue
 * @Description: none
-->
<template>
  <div class="word_cloud">
    <div class="word_cloud_data" id="wordCloudContainer"></div>
  </div>
</template>

<script>
import { WordCloud } from "@antv/g2plot";
export default {
  name: "WordCloudV2",
  props: {
    wordCloudData: {
      default: () => []
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.createWordCloud()
  },
  methods: {
    createWordCloud() {
      const data = this.wordCloudData;
      this.wordCloud = new WordCloud("wordCloudContainer", {
        data,
        height: 250,
        wordField: "name",
        colorField: "name",
        weightField: "weight",
        wordStyle: {
          fontSize: [8, 32],
        },
        tooltip: false,
        random: () => 0.5,
      });
      this.wordCloud.render();
    }
  }
};
</script>
